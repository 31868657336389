import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Logo from '../components/icons/logo'
import Menu from '../components/icons/menu'

import { removeMenuClass, toggleMenuClass } from '../helpers/ui'

import { HeaderLayout } from '../styles/HeaderLayout'
import { ButtonGhost } from '../styles/ButtonLayout'
import { ParagraphM } from '../styles/Typography'

/**
 * The main header of the website.
 * @param {ComponentProps} props
 * @param {String} props.logoBackground
 * @param {String} props.logoBorder
 * @param {String} props.logoText
 * @param {String} props.linkText
 * @param {String} props.menuBackground
 * @param {String} props.menuLines
 * @returns {StatelessComponent}
 */
const Header = ({ logoBackground, logoBorder, logoText, linkText, menuBackground, menuLines }) => (
  <HeaderLayout id="header">
    <Link to="/" onClick={removeMenuClass}>
      <Logo background={logoBackground} border={logoBorder} text={logoText} />
    </Link>
    <div className="menu-group">
      <Link to="/contact" onClick={removeMenuClass}>
        <ParagraphM color={linkText}>Piši nam</ParagraphM>
      </Link>
      <ButtonGhost onClick={toggleMenuClass}>
        <Menu background={menuBackground} lines={menuLines} />
      </ButtonGhost>
    </div>
  </HeaderLayout>
)

Header.propTypes = {
  logoBackground: PropTypes.string,
  logoBorder: PropTypes.string,
  logoText: PropTypes.string,
  linkText: PropTypes.string,
  menuBackground: PropTypes.string,
  menuLines: PropTypes.string,
}

Header.defaultProps = {
  logoBackground: undefined,
  logoBorder: undefined,
  logoText: undefined,
  linkText: undefined,
  menuBackground: undefined,
  menuLines: undefined,
}

export default Header
