import React from 'react'

import { colors } from '../../constants/theme'

const Menu = ({ background = colors.white, lines = colors.green }) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="menu"
    id="menu-icon"
  >
    <rect width="50" height="50" fill={background} />
    <rect x="14" y="17" width="22" height="2" fill={lines} className="menu-line" />
    <rect x="14" y="31" width="22" height="2" fill={lines} className="menu-line" />
    <rect x="21" y="24" width="15" height="2" fill={lines} className="menu-line" />
  </svg>
)

export default Menu
