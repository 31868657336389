import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import faviconPng from '../assets/images/favicon.png'
import faviconIco from '../assets/images/favicon.ico'

/**
 * An SEO handler getting data from each page and assigning in a Helmet.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {String} props.description
 * @param {String} props.image
 * @param {String} props.title
 * @returns {FunctionComponent}
 */
const SEO = ({ children, image, title, description }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <Helmet titleTemplate={title}>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" type="image/png" href={faviconPng} />
      <link rel="icon" href={faviconIco} />
      <meta name="description" content={description || site.siteMetadata.description} />
      <title>{title}</title>

      <meta property="og:title" content={title || site.siteMetadata.title} key="ogtitle" />
      <meta
        property="og:description"
        content={description || site.siteMetadata.description}
        key="ogdesc"
      />
      <meta propery="og:site_name" content={title || site.siteMetadata.title} key="ogsitename" />
      <meta property="og:image" content={image} />

      <meta name="twitter:title" content={title || site.siteMetadata.title} />
      <meta name="twitter:description" content={description || site.siteMetadata.description} />
      <meta name="twitter:site_name" content={title || site.siteMetadata.title} />
      <meta name="twitter:image" content={image} />

      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  image: PropTypes.string,
  title: PropTypes.string,
}

SEO.defaultProps = {
  children: undefined,
  image: 'assets/img', //TODO
  title: undefined,
}

export default SEO
